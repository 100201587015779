<template>
  <div class="authenticated-layout">
    <AuthenticatedNavbar />
    <div class="h-full w-full">
      <TheSidebar />
      <div class="lg:ml-60 relative">
        <main>
          <RouterView v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <component :is="Component" :key="route.name" />
            </transition>
          </RouterView>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Footer from "@/core/shared/components/Layout/Footer.vue";

import AuthenticatedNavbar from "@/shared/components/navbar/AuthenticatedNavbar.vue";
import TheSidebar from "@/shared/components/sidebar/TheSidebar.vue";

export default defineComponent({
  name: "AuthenticatedLayout",
  components: {
    AuthenticatedNavbar,
    TheSidebar,
    Footer,
  },
});
</script>

<style scoped lang="scss">
.authenticated-layout {
  @apply flex flex-col w-full min-h-screen;

  main {
    @apply sm:px-6 md:px-8 py-6 bg-gray-100 min-h-[calc(100vh_-_128px)];
  }
}
</style>
