<template>
  <nav class="space-y-1 px-2">
    <!--Dashboard-->
    <RouterLink
      :to="{ name: 'Dashboard' }"
      :class="[
        { 'sidebar-item-active': route.name === 'Dashboard' },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsDashboardOutlineSolidIcon
        :class="[
          { 'item-icon-active': route.name === 'Dashboard' },
          'item-icon',
        ]"
      />
      {{ t("common.route.dashboard.link") }}
    </RouterLink>
    <!--Certifications-->
    <RouterLink
      v-if="showItem('job_certification')"
      :to="{ name: 'Certifications' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/certification') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsBadgeOutlineIcon
        :class="[
          { 'item-icon-active': startWithPath('/certification') },
          'item-icon',
        ]"
      />
      {{ t("common.route.certifications.link") }}
    </RouterLink>
    <!--Creators-->
    <RouterLink
      v-if="showItem('manage_creator')"
      :to="{ name: 'TheCreators' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/creator') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsBulbOutlineSolidIcon
        :class="[
          { 'item-icon-active': startWithPath('/creator') },
          'item-icon',
        ]"
      />
      {{ t("common.route.creators.link") }}
    </RouterLink>
    <!--Companies-->
    <RouterLink
      v-if="showItem('manage_company')"
      :to="{ name: 'Companies' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/compan') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsUserGroupOutlineIcon
        :class="[{ 'item-icon-active': startWithPath('/compan') }, 'item-icon']"
      />
      {{ t("common.route.companies.link") }}
    </RouterLink>
    <!--Campaigns-->
    <RouterLink
      v-if="showItem('campaign_approval')"
      :to="{ name: 'Campaigns' }"
      :class="[
        {
          'sidebar-item-active':
            startWithPath('/campaign') && !startWithPath('/campaign/recurring'),
        },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsPortfolioOutlineIcon
        :class="[
          {
            'item-icon-active':
              startWithPath('/campaign') &&
              !startWithPath('/campaign/recurring'),
          },
          'item-icon',
        ]"
      />
      {{ t("common.route.campaigns.link") }}
    </RouterLink>
    <!--Jobs-->
    <RouterLink
      v-if="showItem('job_data')"
      :to="{ name: 'Jobs' }"
      :class="[
        {
          'sidebar-item-active':
            startWithPath('/job') && !startWithPath('/job/recurring'),
        },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsBookOutlineSolidIcon
        :class="[
          {
            'item-icon-active':
              startWithPath('/job') && !startWithPath('/job/recurring'),
          },
          'item-icon',
        ]"
      />
      {{ t("common.route.jobs.link") }}
    </RouterLink>
    <!--Recurring campaigns-->
    <RouterLink
      v-if="showItem('recurring_campaign')"
      :to="{ name: 'RecurringCampaigns' }"
      :class="[
        {
          'sidebar-item-active':
            startWithPath('/recurring-campaigns') ||
            startWithPath('/campaign/recurring'),
        },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsPortfolioOutlineIcon
        :class="[
          {
            'item-icon-active':
              startWithPath('/recurring-campaigns') ||
              startWithPath('/campaign/recurring'),
          },
          'item-icon',
        ]"
      />
      {{ t("common.route.recurringCampaigns.link") }}
    </RouterLink>
    <!--Recurring jobs-->
    <RouterLink
      v-if="showItem('recurring_campaign')"
      :to="{ name: 'RecurringJobs' }"
      :class="[
        {
          'sidebar-item-active':
            startWithPath('/recurring-jobs') || startWithPath('/job/recurring'),
        },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsBookOutlineSolidIcon
        :class="[
          {
            'item-icon-active':
              startWithPath('/recurring-jobs') ||
              startWithPath('/job/recurring'),
          },
          'item-icon',
        ]"
      />
      {{ t("common.route.recurringJobs.link") }}
    </RouterLink>
    <!--Integrations -->
    <RouterLink
      v-if="showItem('manage_auth_tiktok_shop')"
      :to="{ name: 'Integrations' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/integrations') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsShareBoldOutlineIcon
        :class="[
          { 'item-icon-active': startWithPath('/integrations') },
          'item-icon',
        ]"
      />
      {{ t("common.route.integrations.link") }}
    </RouterLink>
    <!--TikTok ads-->
    <RouterLink
      v-if="showItem('job_data')"
      :to="{ name: 'TikTokAds' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/tiktok-ads') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsLightningBoltOutlineIcon
        :class="[
          { 'item-icon-active': startWithPath('/tiktok-ads') },
          'item-icon',
        ]"
      />
      {{ t("common.route.tiktokAds.link") }}
    </RouterLink>
    <!--Wire transfers-->
    <RouterLink
      v-if="showItem('wire_transfer')"
      :to="{ name: 'WireTransfers' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/wire-transfer') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsBuildingOutlineSolidIcon
        :class="[
          { 'item-icon-active': startWithPath('/wire-transfer') },
          'item-icon',
        ]"
      />
      {{ t("common.route.wireTransfers.link") }}
    </RouterLink>
    <!--Videos-->
    <RouterLink
      v-if="showItem('manage_video')"
      :to="{ name: 'Videos' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/videos') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsVideoOutlineSolidIcon
        :class="[{ 'item-icon-active': startWithPath('/videos') }, 'item-icon']"
      />
      {{ t("common.route.videos.link") }}
    </RouterLink>
    <!--Chats-->
    <RouterLink
      :to="{ name: 'FlaggedChats' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/chats/flagged') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsChatBubbleOutlineIcon
        :class="[{ 'item-icon-active': startWithPath('/chats') }, 'item-icon']"
      />
      {{ t("common.route.flaggedChats.link") }}
    </RouterLink>
    <!-- Coupons -->
    <RouterLink
      v-if="showItem('bundle_coupons')"
      :to="{ name: 'Coupons' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/coupon') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsTicketOutlineIcon
        :class="[{ 'item-icon-active': startWithPath('/coupon') }, 'item-icon']"
      />
      {{ t("common.route.coupons.link") }}
    </RouterLink>
    <RouterLink
      v-if="showItem('manage_promo_coupons')"
      :to="{ name: 'PromoCoupons' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/promo-coupon') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsTicketOutlineIcon
        :class="[
          { 'item-icon-active': startWithPath('/promo-coupon') },
          'item-icon',
        ]"
      />
      {{ t("common.route.promoCoupons.link") }}
    </RouterLink>
    <!--Logs by IP-->
    <RouterLink
      v-if="showItem('manage_creator')"
      :to="{ name: 'IpSearch' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/ip-address') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsDocumentTextOutlineIcon
        :class="[
          { 'item-icon-active': startWithPath('/ip-address') },
          'item-icon',
        ]"
      />
      {{ t("common.route.ipSearch.link") }}
    </RouterLink>
    <!--Administrators-->
    <RouterLink
      v-if="showItem('manage_admin')"
      :to="{ name: 'Administrators' }"
      :class="[
        { 'sidebar-item-active': startWithPath('/administrator') },
        'group sidebar-item',
      ]"
      @click="closeSidebar()"
    >
      <DsShieldExclamationOutlineSolidIcon
        :class="[
          { 'item-icon-active': startWithPath('/administrator') },
          'item-icon',
        ]"
      />
      {{ t("common.route.administrators.link") }}
    </RouterLink>
  </nav>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import {
  DsBadgeOutlineIcon,
  DsBookOutlineSolidIcon,
  DsBuildingOutlineSolidIcon,
  DsBulbOutlineSolidIcon,
  DsChatBubbleOutlineIcon,
  DsDashboardOutlineSolidIcon,
  DsDocumentTextOutlineIcon,
  DsLightningBoltOutlineIcon,
  DsPortfolioOutlineIcon,
  DsShareBoldOutlineIcon,
  DsShieldExclamationOutlineSolidIcon,
  DsTicketOutlineIcon,
  DsUserGroupOutlineIcon,
  DsVideoOutlineSolidIcon,
} from "@devsalsa/vue-core";

import Browser from "@/core/shared/helpers/Browser";
import Global from "@/core/shared/services/Provide/Global";

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { isSidebarOpen } from "@/shared/components/sidebar/SidebarListener";

defineOptions({
  name: "SidebarItems",
});

const { t } = useI18n();
const route = useRoute();

const permissions = ref<string[]>([]);

onMounted(() => {
  populatePermissions();
});

const populatePermissions = async (): Promise<void> => {
  const accountInfo = await Global.getAccountInfo();
  permissions.value = Object.values(accountInfo.permissions);
};
const showItem = (permission: string): boolean => {
  return permissions.value.includes(permission);
};
const closeSidebar = (): void => {
  if (Browser.isMobile()) {
    isSidebarOpen.value = false;
  }
};
const startWithPath = (path: string): boolean => {
  return route.path.startsWith(path);
};
</script>

<style lang="scss" scoped>
.sidebar-item {
  @apply flex items-center px-2 py-2 text-sm font-medium rounded-md transition duration-200 text-gray-600 hover:bg-gray-100 hover:text-pink-500;

  &-active {
    @apply bg-gray-100 text-pink-500;
  }

  :deep(svg) {
    path {
      @apply stroke-[1.5px];
    }
  }

  .item-icon {
    @apply mr-3 flex-shrink-0 h-6 w-6;

    &-active {
      @apply text-pink-500;
    }
  }
}
</style>
