import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/integrations",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "",
        name: "Integrations",
        component: () => import("@/modules/integrations/views/TikTokShops.vue"),
        meta: {
          title: "common.route.integrations.meta",
          permission: Permission.ManageIntegrationTikTokShop,
        },
        beforeEnter: AuthGuard.withToken,
      },
    ],
  },
];
