import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/",
    children: [
      {
        path: "/promo-coupons",
        name: "PromoCoupons",
        component: () =>
          import("@/modules/promo-coupons/views/PromoCoupons.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.promoCoupons.meta",
          permission: Permission.ManagePromoCoupons,
        },
      },
      {
        path: "/promo-coupon/create",
        name: "CreatePromoCoupon",
        component: () =>
          import("@/modules/promo-coupons/views/CreatePromoCoupon.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.promoCoupons.create.meta",
          permission: Permission.ManagePromoCoupons,
        },
      },
      {
        path: "/promo-coupon/:id/edit",
        name: "EditPromoCoupon",
        component: () =>
          import("@/modules/promo-coupons/views/EditPromoCoupon.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.promoCoupons.edit.meta",
          permission: Permission.ManagePromoCoupons,
        },
      },
      {
        path: "/promo-coupon/:id",
        name: "PromoCouponSummary",
        component: () =>
          import("@/modules/promo-coupons/views/PromoCouponSummary.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.promoCoupons.edit.meta",
          permission: Permission.ManagePromoCoupons,
        },
      },
    ],
  },
];
