import { ref } from "vue";

import { defineStore } from "pinia";

import CertificationService from "@/modules/certification/services/CertificationService";
import CreatorService from "@/modules/creator/services/CreatorService";

import type { Certificate } from "@/modules/certification/services/CertificationService.types";
import type { CreatorExtended } from "@/modules/creator/services/CreatorService.types";

type CertificationApprovalDetails = {
  certificate: Certificate;
  creator: CreatorExtended;
};

export const useCertificationStore = defineStore("certification-store", () => {
  const certificateApprovals = ref<CertificationApprovalDetails[]>([]);

  const loadNextCertificationApproval = async (
    certificationId: number
  ): Promise<CertificationApprovalDetails | undefined> => {
    const nextApproval =
      await CertificationService.getNextApproval(certificationId);
    if (Object.keys(nextApproval).length > 0) {
      return retrieveAndQueueCertificationApproval(
        nextApproval.job_type_id,
        nextApproval.creator_id
      );
    }

    return undefined;
  };

  const getCertificationApproval = async (
    certificationId: number,
    creatorId: number
  ): Promise<CertificationApprovalDetails> => {
    const found = certificateApprovals.value.find((certification) => {
      return (
        certification.certificate.job_type_id === certificationId &&
        certification.certificate.creator_id === creatorId
      );
    });
    if (found) {
      return found;
    }

    return retrieveAndQueueCertificationApproval(certificationId, creatorId);
  };

  const nextCertificationApproval = ():
    | CertificationApprovalDetails
    | undefined => {
    return certificateApprovals.value.length > 0
      ? certificateApprovals.value[0]
      : undefined;
  };

  const deleteCertificationApproval = (
    certificationId: number,
    creatorId: number
  ): void => {
    const index = certificateApprovals.value.findIndex((certification) => {
      return (
        certification.certificate.job_type_id === certificationId &&
        certification.certificate.creator_id === creatorId
      );
    });
    if (index > -1) {
      certificateApprovals.value.splice(index, 1);
    }
  };

  const retrieveAndQueueCertificationApproval = async (
    certificationId: number,
    creatorId: number
  ): Promise<CertificationApprovalDetails> => {
    const certificateApproval = await retrieveCertificate(
      certificationId,
      creatorId
    );
    queueCertificationApproval(certificateApproval);
    return certificateApproval;
  };

  const retrieveCertificate = async (
    certificationId: number,
    creatorId: number
  ): Promise<CertificationApprovalDetails> => {
    const certificate = await CertificationService.get(
      creatorId,
      certificationId
    );
    const creator = await CreatorService.get(creatorId);
    return {
      certificate: certificate,
      creator: creator,
    };
  };

  const queueCertificationApproval = (
    certificationApproval: CertificationApprovalDetails
  ) => {
    const index = certificateApprovals.value.findIndex((certification) => {
      return (
        certification.certificate.job_type_id ===
          certificationApproval.certificate.job_type_id &&
        certification.certificate.creator_id ===
          certificationApproval.certificate.creator_id
      );
    });
    if (index === -1) {
      // Only insert if not exists
      certificateApprovals.value.push(certificationApproval);
    }
  };

  return {
    loadNextCertificationApproval,
    getCertificationApproval,
    nextCertificationApproval,
    deleteCertificationApproval,
    certificateApprovals,
  };
});
