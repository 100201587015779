import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { useCertificationStore } from "@/modules/certification/store/CertificationStore";

export default class CertificationGuard {
  static async withCertificationApproval(
    to: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const certificationId = Number(to.params.id);

    const { loadNextCertificationApproval } = useCertificationStore();
    const certificationApproval =
      await loadNextCertificationApproval(certificationId);

    if (certificationApproval) {
      return next({
        name: "CertificationApproval",
        params: {
          id: certificationId,
          creatorId: certificationApproval.creator.id,
        },
      });
    }

    return next({
      name: "PendingCertifications",
      params: {
        certificationId: certificationId,
      },
    });
  }
}
