import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { useJobContentApprovalStore } from "@/modules/content/stores/JobContentApprovalStore";

export default class ContentGuard {
  static async withJobContentApproval(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const { loadNextJobContentApproval } = useJobContentApprovalStore();
    const jobContentApproval = await loadNextJobContentApproval();
    if (jobContentApproval) {
      return next({
        name: "ContentApproval",
        params: {
          id: jobContentApproval.job.id,
        },
      });
    }

    return next({
      name: "PendingContents",
    });
  }
}
