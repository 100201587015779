import { ApiService } from "@/core/shared/services/ApiService";

import type {
  Certificate,
  CertificationsList,
  CertificationUpdated,
} from "@/modules/certification/services/CertificationService.types";

/**
 * @class CertificationService
 */
export default class CertificationService {
  static readonly VIDEO_HOW_TO = 1;
  static readonly VIDEO_UNBOXING = 2;
  static readonly VIDEO_PRODUCT_REVIEW = 3;
  static readonly VIDEO_PRODUCT_DEMO = 4;
  static readonly IMAGE_LIFESTYLE_SHOT = 5;
  static readonly IMAGE_SELFIE_PRODUCT = 6;
  static readonly IMAGE_PRODUCT_DEMO = 7;
  static readonly IMAGE_PRODUCT_ONLY = 8;

  /**
   * Certification retry
   * @endpoint /admin/creators/${creatorId}/certifications/${certificationId}/retry
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param certificationId {number} Job type id
   * @param message {string} Message
   * @param redirectUrl {string} Redirect url
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationUpdated>}
   */
  static retry(
    creatorId: number,
    certificationId: number,
    message: string,
    redirectUrl: string
  ): Promise<CertificationUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/certifications/${certificationId}/retry`,
      {
        message: message,
        redirect_url: redirectUrl,
      }
    );
  }

  /**
   * Certification ban
   * @endpoint /admin/creators/${creatorId}/certifications/${certificationId}/ban
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param certificationId {number} Job type id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationUpdated>}
   */
  static ban(
    creatorId: number,
    certificationId: number
  ): Promise<CertificationUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/certifications/${certificationId}/ban`
    );
  }

  /**
   * Certification approve
   * @endpoint /admin/creators/${creatorId}/certifications/${certificationId}/approve
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param certificationId {number} Job type id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationUpdated>}
   */
  static approve(
    creatorId: number,
    certificationId: number
  ): Promise<CertificationUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/certifications/${certificationId}/approve`
    );
  }

  /**
   * Get a creator certification
   * @endpoint /admin/creators/${creatorId}/certifications/${certificationId}
   * @httpMethod GET
   * @param creatorId {number} Creator impersonation id
   * @param certificationId {number} Job type id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Certificate>}
   */
  static get(creatorId: number, certificationId: number): Promise<Certificate> {
    return ApiService.get(
      `/admin/creators/${creatorId}/certifications/${certificationId}`
    );
  }

  /**
   * Job content delay approval
   * @endpoint /admin/creators/${creatorId}/certifications/${certificationId}/delay
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param certificationId {number} Job type id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationUpdated>}
   */
  static delay(
    creatorId: number,
    certificationId: number
  ): Promise<CertificationUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/certifications/${certificationId}/delay`
    );
  }

  /**
   * Get a list of pending certifications
   * @endpoint /admin/creators/certifications/${certificationId}/${page}/${rows}
   * @httpMethod GET
   * @param certificationId {number} Certification ID
   * @param page {number} Page
   * @param rows {number} Rows per page
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationsList>}
   */
  static list(
    certificationId: number,
    page: number,
    rows: number
  ): Promise<CertificationsList> {
    return ApiService.get(
      `/admin/creators/certifications/${certificationId}/${page}/${rows}`
    );
  }

  /**
   * Get a latest of pending certification
   * @endpoint /admin/creators/latest-certifications/{certificationId}
   * @httpMethod GET
   * @param certificationId {number} Certification ID
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CertificationsList>}
   */
  static getNextApproval(certificationId: number): Promise<Certificate> {
    return ApiService.get(
      `/admin/creators/latest-certifications/${certificationId}`
    );
  }
}
