import { ApiService } from "@/core/shared/services/ApiService";

import type {
  AmazonInfluencer,
  Creator,
  CreatorExtended,
  CreatorLogs,
  CreatorPaymentUpdated,
  CreatorSuspiciousFlags,
  CreatorTransactionResponse,
  CreatorUpdated,
  ImpersonateToken,
  PaymentsPendingApprovalResponse,
  PendingApprovalAmazonInfluencerResponse,
  PendingApprovalCreatorsResponse,
} from "@/modules/creator/services/CreatorService.types";
import type { WalletTransactionsResponse } from "@/shared/services/WalletService.types";

import type { WalletTransactionType } from "@/shared/enums/WalletTransactionTypeEnum";

/**
 * @class CreatorService
 */
export default class CreatorService {
  /**
   * Get data of a creator
   * @endpoint /admin/creators/{creatorId}
   * @httpMethod GET
   * @param creatorId {number} Creator impersonation id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static get(creatorId: number): Promise<CreatorExtended> {
    return ApiService.get(`/admin/creators/${creatorId}`);
  }

  /**
   * Get latest data of a creator on the onboarding approval
   * @endpoint admin/creators/latest/onboarding-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static getLatestPendingApproval(): Promise<CreatorExtended> {
    return ApiService.get(`/admin/creators/latest/onboarding-approvals`);
  }

  /**
   * Suspend creator
   * @endpoint /admin/creators/{creatorId}/suspend
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param suspendedReason {string} Suspended reason
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static suspend(
    creatorId: number,
    suspendedReason: string
  ): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/suspend`, {
      suspended_reason: suspendedReason,
    });
  }

  /**
   * Unsuspend creator
   * @endpoint /admin/creators/{creatorId}/unsuspend
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static unsuspend(creatorId: number): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/unsuspend`);
  }

  /**
   * Set creator ethnicity
   * @endpoint /admin/creators/{creatorId}/ethnicity
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param ethnicityId {number} Ethnicity id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static setEthnicity(
    creatorId: number,
    ethnicityId: number
  ): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/ethnicity`, {
      ethnicity: ethnicityId,
    });
  }

  /**
   * @endpoint /admin/creators/{creatorId}/gender
   * @httpMethod PUT
   * @param creatorId
   * @param gender
   */
  static setGender(creatorId: number, gender: number): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/gender`, { gender });
  }

  /**
   * Get impersonate creator using an identifier
   * @endpoint /admin/creators/{creatorId}/impersonate
   * @httpMethod GET
   * @param creatorId {number} Creator impersonation id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ImpersonateToken>}
   */
  static impersonate(creatorId: number): Promise<ImpersonateToken> {
    return ApiService.get(`/admin/creators/${creatorId}/impersonate`);
  }

  /**
   * Admin creator softban
   * @endpoint /admin/creators/{creatorId}/softban
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param reason {string}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static softBan(creatorId: number, reason: string): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/softban`, { reason });
  }

  /**
   * Admin creator unsoftban
   * @endpoint /admin/creators/{creatorId}/unsoftban
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static unSoftBan(creatorId: number): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/unsoftban`);
  }

  /**
   * Approve/disapprove the onboarding for a creator
   * @endpoint /admin/creators/{creator_id}/approve-onboarding
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param data {Record<string, string>} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static processOnboarding(
    creatorId: number,
    data: Record<string, string>
  ): Promise<CreatorUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/approve-onboarding`,
      data
    );
  }

  /**
   * Get creators pending approval
   * @endpoint /admin/creators/onboarding-approvals/${page}/${rows}
   * @httpMethod GET
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static listPendingApproval(
    page = 0,
    rows = 25
  ): Promise<PendingApprovalCreatorsResponse> {
    return ApiService.get(
      `/admin/creators/onboarding-approvals/${page}/${rows}`
    );
  }

  /**
   * Get Amazon influencers pending approval
   * @endpoint /admin/creators/amazon-approvals
   * @httpMethod GET
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<PendingApprovalAmazonInfluencerResponse>}
   */
  static listAmazonPendingApproval(
    page: number = 0,
    rows: number = 25
  ): Promise<PendingApprovalAmazonInfluencerResponse> {
    return ApiService.get(`/admin/creators/amazon-approvals/${page}/${rows}`);
  }

  /**
   * Get latest data of an amazon influencer on the onboarding approval
   * @endpoint /admin/creators/latest-amazon-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<AmazonInfluencer>}
   */
  static getLatestAmazonPendingApproval(): Promise<AmazonInfluencer> {
    return ApiService.get(`/admin/creators/latest-amazon-approvals`);
  }

  /**
   * Get payments pending approval
   * @endpoint /admin/creators/payments-approvals/${page}/${rows}
   * @httpMethod GET
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<PaymentsPendingApprovalResponse>}
   */
  static listPaymentsPendingApproval(
    page = 0,
    rows = 25
  ): Promise<PaymentsPendingApprovalResponse> {
    return ApiService.get(`/admin/creators/payments-approvals/${page}/${rows}`);
  }

  /**
   * Get latest data of a payment on the onboarding approval
   * @endpoint /admin/creators/latest-payments-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Creator>}
   */
  static getLatestPaymentPendingApproval(): Promise<Creator> {
    return ApiService.get(`/admin/creators/latest-payments-approvals`);
  }

  /**
   * Get creator logs
   * @endpoint /admin/creators/${creatorId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param creatorId {number} Creator impersonation id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @return {Promise<CreatorLogs>}
   */
  static logs(creatorId: number, page = 0, rows = 25): Promise<CreatorLogs> {
    return ApiService.get(`/admin/creators/${creatorId}/logs/${page}/${rows}`);
  }

  /**
   * Get creator wallet earning logs
   * @endpoint /admin/creators/${creatorId}/earnings-wallet-transactions/${page}/${rows}
   * @httpMethod GET
   * @param creatorId {number} Creator impersonation id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<WalletTransactionsResponse>}
   */
  static getEarningsTransactions(
    creatorId: number,
    page = 0,
    rows = 25
  ): Promise<WalletTransactionsResponse> {
    return ApiService.get(
      `/admin/creators/${creatorId}/earnings-wallet-transactions/${page}/${rows}`
    );
  }

  /**
   * Admin creator is PRO
   * @endpoint /admin/creators/{creatorId}/possible-pro-level
   * @httpMethod PUT
   * @param creatorId {number} Creator impersonation id
   * @param value {number}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static toggleProLevel(
    creatorId: number,
    value: number
  ): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/possible-pro-level`, {
      possible_pro_level: value,
    });
  }

  /**
   * Get all wallet transactions for a creator
   * @endpoint /admin/creators/{creatorId}/wallet-transactions/{page}/{rows}
   * @httpMethod POST
   * @param creatorId {number} Creator id
   * @param types { WalletTransactionType[] } Creator wallet types
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorTransactionResponse>}
   */
  static getWalletTransactions(
    creatorId: number,
    types: WalletTransactionType[],
    page = 0,
    rows = 25
  ): Promise<CreatorTransactionResponse> {
    return ApiService.post(
      `/admin/creators/${creatorId}/wallet-transactions/${page}/${rows}`,
      { wallet_types: types }
    );
  }

  /**
   * Update creator payment approval
   * @endpoint /admin/creators/${creatorId}/allow-payments
   * @httpMethod PUT
   * @param creatorId
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorPaymentUpdated>}
   */
  static approvePayment(creatorId: number): Promise<CreatorPaymentUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/allow-payments`);
  }

  /**
   * Update creator payment approval
   * @endpoint /admin/creators/${creatorId}/disallow-payments
   * @httpMethod PUT
   * @param creatorId
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorPaymentUpdated>}
   */

  static denyPayment(creatorId: number): Promise<CreatorPaymentUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/disallow-payments`);
  }

  /**
   * @endpoint /admin/creators/${creatorId}/flags
   * @httpMethod PUT
   * @param creatorId
   */
  static flags(creatorId: number): Promise<CreatorSuspiciousFlags[]> {
    return ApiService.get(`/admin/creators/${creatorId}/flags`);
  }

  /**
   * 2FA status for the company
   * @endpoint /admin/creators/{company_id}/disable-2fa
   * @httpMethod POST
   * @param creatorId {number}
   * @param is_disable
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static set2FA(
    creatorId: number,
    is_disable: number
  ): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/disable-2fa`, {
      disable_2fa: is_disable,
    });
  }

  /**
   * i_ship_it_available status
   * @endpoint /admin/creators/{creator_id}/enable-i-ship-it-available
   * @httpMethod PUT
   * @param creatorId {number}
   * @param enabled {number}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static setIShipItAvailable(
    creatorId: number,
    enabled: number
  ): Promise<CreatorUpdated> {
    return ApiService.put(
      `/admin/creators/${creatorId}/enable-i-ship-it-available`,
      {
        i_ship_it_available: enabled,
      }
    );
  }

  /**
   * Update creator email
   * @endpoint /admin/creators/${creatorId}/email
   * @httpMethod PUT
   * @param creatorId
   * @param email
   * @param end
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorUpdated>}
   */
  static updateEmail(
    creatorId: number,
    email: string,
    end: boolean
  ): Promise<CreatorUpdated> {
    return ApiService.put(`/admin/creators/${creatorId}/email`, {
      email,
      end,
    });
  }
}
