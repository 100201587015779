import { ref } from "vue";

import { defineStore } from "pinia";

import CampaignService from "@/modules/campaign/services/CampaignService";
import CompanyService from "@/modules/company/services/CompanyService";
import JobContentService from "@/modules/content/services/JobContentService";
import CreatorService from "@/modules/creator/services/CreatorService";
import JobService from "@/modules/job/services/JobService";

import type { Campaign } from "@/modules/campaign/services/CampaignService.types";
import type { Company } from "@/modules/company/services/CompanyService.types";
import type { JobContentResponse } from "@/modules/content/services/JobContentService.types";
import type { Creator } from "@/modules/creator/services/CreatorService.types";
import type { Job } from "@/modules/job/services/JobService.types";

import { JobContentStatus } from "@/modules/job/enums/JobContentStatusEnum";

type JobContentApprovalDetails = {
  job: Job;
  contentRejected: JobContentResponse;
  content: JobContentResponse;
  campaign: Campaign;
  company: Company;
  creator: Creator;
};

export const useJobContentApprovalStore = defineStore(
  "job-content-approval-store",
  () => {
    const jobContentApprovals = ref<JobContentApprovalDetails[]>([]);

    async function retrieveJobContentApproval(
      jobId: number
    ): Promise<JobContentApprovalDetails> {
      const job = await JobService.get(jobId);
      const contentRejected = await JobContentService.list(jobId, {
        job_content_status: JobContentStatus.Denied,
      });
      const content = await JobContentService.list(jobId, {
        job_content_status: JobContentStatus.PendingApproval,
      });
      const campaign = await CampaignService.get(job.campaign_id);
      const company = await CompanyService.get(job.company_id);
      const creator = await CreatorService.get(job.creator_id);
      return {
        job: job,
        contentRejected: contentRejected,
        content: content,
        campaign: campaign,
        company: company,
        creator: creator,
      };
    }

    function queueJobContentApproval(
      jobContentApproval: JobContentApprovalDetails
    ) {
      jobContentApprovals.value.push(jobContentApproval);
    }

    async function retrieveAndQueueJobContentApproval(
      jobId: number
    ): Promise<JobContentApprovalDetails> {
      const jobContentApproval = await retrieveJobContentApproval(jobId);
      queueJobContentApproval(jobContentApproval);
      return jobContentApproval;
    }

    async function getJobContentApproval(
      jobId: number
    ): Promise<JobContentApprovalDetails> {
      const found = jobContentApprovals.value.find(
        (job) => job.job.id === jobId
      );
      if (found) {
        return found;
      }

      return retrieveAndQueueJobContentApproval(jobId);
    }

    function nextJobContentApproval(): JobContentApprovalDetails | undefined {
      return jobContentApprovals.value.length > 0
        ? jobContentApprovals.value[0]
        : undefined;
    }

    async function loadNextJobContentApproval(): Promise<
      JobContentApprovalDetails | undefined
    > {
      const nextApproval = await JobContentService.getNextJobContentApproval();
      if (Object.keys(nextApproval).length > 0) {
        return retrieveAndQueueJobContentApproval(nextApproval.job_id);
      }

      return undefined;
    }

    function deleteJobContentApproval(jobId: number): void {
      const index = jobContentApprovals.value.findIndex(
        (job) => job.job.id === jobId
      );
      if (index > -1) {
        jobContentApprovals.value.splice(index, 1);
      }
    }

    return {
      nextJobContentApproval,
      getJobContentApproval,
      deleteJobContentApproval,
      loadNextJobContentApproval,
      jobContentApprovals,
    };
  }
);
