import deDeCore from "@devsalsa/vue-core/dist/locales/de-DE.json";
import enAuCore from "@devsalsa/vue-core/dist/locales/en-AU.json";
import enCaCore from "@devsalsa/vue-core/dist/locales/en-CA.json";
import enGbCore from "@devsalsa/vue-core/dist/locales/en-GB.json";
import enUsCore from "@devsalsa/vue-core/dist/locales/en-US.json";

import type {
  IntlDateTimeFormat,
  IntlDateTimeFormats,
  IntlNumberFormats,
} from "vue-i18n";
import { createI18n } from "vue-i18n";

import deDe from "@/locales/de-DE.json";
import enAu from "@/locales/en-AU.json";
import enCa from "@/locales/en-CA.json";
import enGb from "@/locales/en-GB.json";
import enUs from "@/locales/en-US.json";

const setDateTimeFormats: IntlDateTimeFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  time: {
    hour: "numeric",
    minute: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  },
};

const datetimeFormats: IntlDateTimeFormats = {
  "en-US": setDateTimeFormats,
};

const numberFormats: IntlNumberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "en-US",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en-US",
  messages: {
    "en-US": { ...enUs, ...enUsCore },
    "en-GB": { ...enGb, ...enGbCore },
    "en-CA": { ...enCa, ...enCaCore },
    "en-AU": { ...enAu, ...enAuCore },
    "de-DE": { ...deDe, ...deDeCore },
  },
  datetimeFormats,
  numberFormats,
});
