import { ApiService } from "@/core/shared/services/ApiService";

import type {
  Job,
  JobMediations,
  JobsLogs,
  JobUpdated,
  RecurringJobExtended,
} from "@/modules/job/services/JobService.types";
import type { ChatMessageInfo } from "@/shared/services/ChatService.types";

/**
 * @class JobService
 */
export default class JobService {
  /**
   * Get job data
   * @endpoint /admin/jobs/{jobId}
   * @httpMethod GET
   * @param jobId {number} Job id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Job>}
   */
  static get(jobId: number): Promise<Job> {
    return ApiService.get(`/admin/jobs/${jobId}`);
  }

  /**
   * Get list job mediation
   * @endpoint /admin/jobs/mediations/{page}/{rowPerPage}
   * @httpMethod GET
   * @param page {number} Page
   * @param rowPerPage {number} Row per page
   * @return {Promise<JobMediations>}
   */
  static getJobMediations(
    page: number,
    rowPerPage: number
  ): Promise<JobMediations> {
    return ApiService.get(`/admin/jobs/mediations/${page}/${rowPerPage}`);
  }

  /**
   * Refund reimbursement for the job.
   * @endpoint /admin/jobs/${jobId}/refund-reimbursement
   * @httpMethod PUT
   * @param jobId
   * @param percentageRefund
   * @return {Promise<JobUpdated>}
   */
  static processMediation(
    jobId: number,
    percentageRefund: number
  ): Promise<JobUpdated> {
    return ApiService.put(`/admin/jobs/${jobId}/refund-reimbursement`, {
      percentage_refund: percentageRefund,
    });
  }

  /**
   * Put job cancel
   * @endpoint /admin/jobs/${jobId}/cancel
   * @httpMethod PUT
   * @param jobId {number} Job impersonation id
   * @param userType {number} User type
   * @param cancelReason {string} Cancel reason
   * @return {Promise<JobUpdated>}
   */
  static cancel(
    jobId: number,
    userType: number,
    cancelReason: string
  ): Promise<JobUpdated> {
    return ApiService.put(`/admin/jobs/${jobId}/cancel`, {
      user_type: userType,
      reason: cancelReason,
    });
  }

  /**
   * Get jobs logs
   * @endpoint /admin/jobs/${jobId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param jobId {number} Job id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @return {Promise<JobsLogs>}
   */
  static logs(jobId: number, page = 0, rows = 25): Promise<JobsLogs> {
    return ApiService.get(`/admin/jobs/${jobId}/logs/${page}/${rows}`);
  }

  /**
   * Get the сhat history for a job
   * @endpoint /admin/jobs/{jobId}/chat-messages
   * @httpMethod GET
   * @param jobId {number} Job id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ChatMessageInfo[]>}
   */
  static getChatHistory(jobId: number): Promise<ChatMessageInfo[]> {
    return ApiService.get(`/admin/jobs/${jobId}/chat-messages`);
  }

  /**
   * Update job rating/review
   * @endpoint /admin/jobs/${jobId}/rating
   * @httpMethod PUT
   * @param jobId {number} Job impersonation id
   * @param rating {number} User rating
   * @param review {string} User review
   * @return {Promise<JobUpdated>}
   */
  static updateJobRating(
    jobId: number,
    rating: number,
    review: string
  ): Promise<JobUpdated> {
    return ApiService.put(`/admin/jobs/${jobId}/rating`, {
      rating: rating,
      review: review,
    });
  }

  /**
   * Get recurring-contract data
   * @endpoint /admin/recurring-contracts/{jobId}
   * @httpMethod GET
   * @param jobId {number} Job id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<RecurringJobExtended>}
   */
  static getRecurringJob(jobId: number): Promise<RecurringJobExtended> {
    return ApiService.get(`/admin/recurring-contracts/${jobId}`);
  }

  /**
   * Get recurring-contract logs
   * @endpoint /admin/recurring-contracts/${jobId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param jobId {number} Job id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @return {Promise<JobsLogs>}
   */
  static getRecurringJobLogs(
    jobId: number,
    page = 0,
    rows = 25
  ): Promise<JobsLogs> {
    return ApiService.get(
      `/admin/recurring-contracts/${jobId}/logs/${page}/${rows}`
    );
  }

  /**
   * Get job сhat рistory
   * @endpoint /admin/recurring-contracts/{jobId}/chat-messages
   * @httpMethod GET
   * @param jobId {number} recurring-contract id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ChatMessageInfo[]>}
   */
  static getRecurringJobChatHistory(jobId: number): Promise<ChatMessageInfo[]> {
    return ApiService.get(`/admin/recurring-contracts/${jobId}/chat-messages`);
  }

  /**
   * Cancel job recurring-contracts
   * @endpoint /admin/recurring-contracts/${jobId}/cancel
   * @httpMethod PUT
   * @param jobId {number} Job recurring-contracts id
   * @return {Promise<JobUpdated>}
   */
  static cancelRecurringJob(jobId: number): Promise<JobUpdated> {
    return ApiService.put(`/admin/recurring-contracts/${jobId}/cancel`);
  }
}
