import { useDsNavigationLoaderStore } from "@devsalsa/vue-core";

import AuthRoutes from "@/core/modules/account/router";
import AdministratorRoutes from "@/core/modules/administrator/router";
import ProfileRoutes from "@/core/modules/profile/router";
import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";

import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import i18n from "@/i18n";
import CampaignRoutes from "@/modules/campaign/router";
import CertificationRoutes from "@/modules/certification/router";
import ChatRoutes from "@/modules/chat/router";
import CompanyRoutes from "@/modules/company/router";
import ContentRoutes from "@/modules/content/router";
import CouponsRoutes from "@/modules/coupons/router";
import CreatorRoutes from "@/modules/creator/router";
import Integrations from "@/modules/integrations/router";
import IpAddressRoutes from "@/modules/ip-address/router";
import JobRoutes from "@/modules/job/router";
import PortfolioRoutes from "@/modules/portfolio/router";
import PromoCouponsRoutes from "@/modules/promo-coupons/router";
import RecurringCampaignRoutes from "@/modules/recurring-campaign/router";
import RecurringJobRoutes from "@/modules/recurring-job/router";
import TiktokRoutes from "@/modules/tiktok-ad/router";
import WireTransferRoutes from "@/modules/transfers/router";
import VideoRoutes from "@/modules/video/router";
import PageNotFoundRoutes from "@/shared/modules/page-not-found/router";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.dashboard.meta",
        },
      },
    ],
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AuthRoutes,
  PageNotFoundRoutes,
  ProfileRoutes,
  AdministratorRoutes,
  CompanyRoutes,
  CreatorRoutes,
  CertificationRoutes,
  CampaignRoutes,
  RecurringCampaignRoutes,
  ContentRoutes,
  JobRoutes,
  RecurringJobRoutes,
  TiktokRoutes,
  WireTransferRoutes,
  PortfolioRoutes,
  VideoRoutes,
  ChatRoutes,
  CouponsRoutes,
  IpAddressRoutes,
  PromoCouponsRoutes,
  Integrations
);

const preloader = window.document.getElementById("preloader") as HTMLElement;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    } else if (
      to.name === from.name &&
      to.hash !== "" &&
      to.hash !== from.hash
    ) {
      return;
    }
    return {
      top: 0,
      left: 0,
      behavior: "smooth",
    };
  },
});
router.onError((error: unknown) => {
  console.log("VueJS router error handler", error);
  ErrorHandler.handle(error);
});
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (preloader.classList.contains("hidden")) {
      useDsNavigationLoaderStore().enable();
    }
    window.document.title = `${
      import.meta.env.VITE_APP_TITLE
    } - ${i18n.global.t(<string>to.meta.title)}`;
    next();
  }
);
router.afterEach(() => {
  //Hide the preloader if not hidden yet.
  preloader.classList.add("hidden");
  useDsNavigationLoaderStore().disable();
});

export default router;
